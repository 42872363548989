import React from 'react';

function Renu(){
	return(<div><p>GLOBAL RECYCLING STANDARD</p><p>Global Recycle Standard is an international, voluntary, full product standard that sets requirements for third-party certification of recycled content, chain of custody, social and environmental practices and chemical&nbsp;restrictions.</p><p>ALLIED Feather + Down&#39;s RENU:Performance Recycled Down is an insulation with a positive impact on the environment with ALLIED helping to recycle over 1 million pounds of textile waste annually. That’s equivalent to taking over 260 vehicles off the road every year.</p>
	</div>);
}

function RenuFR(){
	return(<div><p>GLOBAL RECYCLING STANDARD</p><p>Global Recycle Standard est une norme internationale, volontaire, portant sur l&#39;ensemble du produit, qui fixe des exigences pour la certification par une tierce partie du contenu recyclé, de la chaîne de contrôle, des pratiques sociales et environnementales et des restrictions&nbsp;chimiques.</p><p>Le duvet recyclé RENU:Performance d&#39;ALLIED Feather + Down est un isolant qui a un impact positif sur l&#39;environnement. En effet, ALLIED contribue à recycler plus d&#39;un million de livres de déchets textiles par an. Cela équivaut à retirer plus de 260 véhicules de la circulation chaque année.</p>
	</div>);
}

function RDS(){
  return(<div className="rds-desc"><p>RESPONSIBLE DOWN STANDARD</p><p>In 2012, ALLIED set out with The North Face, Textile Exchange and other stakeholders to build what would become the Responsible Down&nbsp;Standard.</p><p> Working with animal welfare groups, auditing bodies and brands, we realize that any claims to animal welfare need to consider <a href="https://alliedfeather.com/info-room/down-101/animal-welfare-certification" target="_blank" rel="noopener noreferrer">Brambel’s Five Freedom of Animal Welfare,</a> not just the main issues we face as an industry in live plucking and forced feeding. The RDS now follows this model ensuring robust positive treatment of all animals in the down supply chain.</p><p>ALLIED Feather + Down is certified to the RDS by IDFL license number 012377.</p></div>);
}

function RDSFR(){
  return(<div className="rds-desc"><p>RESPONSIBLE DOWN STANDARD</p><p>En 2012, ALLIED a entrepris, avec The North Face, Textile Exchange et d&#39;autres parties prenantes, de construire ce qui allait devenir la norme du duvet&nbsp;responsable.</p><p>En travaillant avec des groupes de protection des animaux, des organismes d&#39;audit et des marques, nous nous rendons compte que toute revendication en matière de bien-être animal doit prendre en compte <a href="https://alliedfeather.com/info-room/down-101/animal-welfare-certification" target="_blank" rel="noopener noreferrer">Brambel’s Five Freedom of Animal Welfare,</a> et pas seulement les principaux problèmes auxquels nous sommes confrontés en tant qu&#39;industrie dans la plumaison des animaux vivants et l&#39;alimentation forcée. Le RDS suit désormais ce modèle, garantissant un traitement positif et solide de tous les animaux dans la chaîne d&#39;approvisionnement du duvet.</p><p>ALLIED Feather + Down est certifié RDS par le numéro de licence 012377 de l&#39;IDFL.</p></div>); 
}

function EB0502(){
  return(<div className="rds-desc"><p>All Eddie Bauer down has been sourced from thoroughly audited farms to ensure the best possible animal welfare practices and processed in ALLIED's sustainable processing methods to create the best possible insulation on and for the&nbsp;planet.</p></div>);
}

function EB0502FR(){
  return(<div className="rds-desc"><p>Tous les duvets Eddie Bauer proviennent de fermes ayant fait l'objet d'un audit approfondi afin de garantir les meilleures pratiques possibles en matière de bien-être animal. Ils sont traités selon les méthodes de traitement durable d'ALLIED afin de créer la meilleure isolation possible sur et pour la&nbsp;planète.</p></div>);
}

function RDSLogic(props){  
  if(props.brand == "0502"){
    //add language handling
    return <></>
    
  } else if(props.rds == true){
	switch(props.activeLang){
    case "fr":	    
    return <RDSFR />;
    break;
    default:
    return <RDS />;
    break;
    }    
  }else{
	return <></>
  }
}

function GRSLogic(props){
  if(props.grs == true){      
    switch(props.activeLang){
    case "fr":	    
    return <RenuFR />;
    break;
    default:
    return <Renu />;
    break;
    }
    
  }else{
	return <></>
  }
}

function EBLogic(props){
  switch(props.activeLang){
  case "fr":	    
  return <EB0502FR />;
  break;
  default:
  return <EB0502 />;
  break;
  }  
}

function Blue(){
return(<div className="blue-desc"><p>BLUESIGN</p><p>bluesign® gives you the assurance that only companies that act responsibly were involved in the manufacture of a product that is designated with the bluesign® product&nbsp;label.<br/><br/>ALLIED Feather + Down have used the best technologies available, used resources responsibly and taken care to minimize the impacts on people and the environment. With your purchase of products that carry the bluesign® product&nbsp;label, you are supporting companies in their sustainable development and become a part of a unique&nbsp;movement.</p></div>);
}

function BlueFR(){
return(<div className="blue-desc"><p>BLUESIGN</p><p>bluesign® vous donne l&#39;assurance que seules des entreprises agissant de manière responsable ont participé à la fabrication d&#39;un produit désigné par le label&nbsp;bluesign®.<br/><br/>ALLIED Feather + Down ont utilisé les meilleures technologies disponibles, utilisé les ressources de manière responsable et pris soin de minimiser les impacts sur les personnes et l&#39;environnement. En achetant des produits portant le label bluesign®, vous soutenez les entreprises dans leur développement durable et faites partie d&#39;un mouvement unique.</p></div>);
}

function SpecificCertification(props){
  switch(props.activeLang){
    case "fr":	    
    return <BlueFR />;
    break;
    default:
    return <Blue />;
    break;
  }
}

const Certification = (props) => {   	
	
    return (<div className="content-ac cr hide">
    <input
      className="ac-input top"
      id="ac-9afd"
      name="ac-9afd"
      data-id="2400"
      data-slug="CERTIFICATIONS"
      type="checkbox"      
      onChange={ () => props.changeWord('certs clicked') }   
    />
    <label className="ac-label" htmlFor="ac-9afd">
    { //change to props.brand
      props.brand == "0502" ? "RESPONSIBLY SOURCED DOWN / " : ""
    }    
    
    { props.rds && props.brand != "0502" ? "RDS / " : "" }
    
    { props.grs ? "GRS / " : "" }bluesign® / OEKO-TEX</label>
    <article className="ac-text ac-9afd"><div className="single-post-interior" tabIndex="0">      
      { props.brand == "0502" ? <EBLogic activeLang = { props.activeLang }/> : "" }
      <p>CERTIFICATIONS</p>
      <RDSLogic brand = {props.brand} rds = { props.rds} activeLang = { props.activeLang }/>
      <GRSLogic grs = { props.grs} activeLang = { props.activeLang }/>        
      <SpecificCertification
        activeLang = { props.activeLang }
      />
    </div>
    <footer></footer>
    </article>
  </div>);    
}

export default Certification;
import React from 'react';


function HyperDryFR(props){
return(<><p>HyperDry<br/>Fluourocarbon Free Water Resistant Down</p><p>Le duvet contenu dans ce produit a été traité avec la technologie exclusive ALLIED de résistance à l&#39;eau sans fluorocarbone pour vous garder au chaud et au sec, quelles que soient les conditions&nbsp;météorologiques.</p><p><a href="//alliedfeather.com/innovation/hyperdry?ref=tmd">plus d&#39;infos >></a></p></>)	
}

function HyperDry(props){
return(<><p>HyperDry<br/>Fluourocarbon Free Water Resistant Down</p><p>The down inside this product has been treated with ALLIED&#8217;s proprietary fluorocarbon free water resistant technology to keep you warm and dry no matter what the weather has in store.</p><p><a href="//alliedfeather.com/innovation/hyperdry?ref=tmd">more info >></a></p></>)
}
 
function ExpeDryFR(){
  return(<>
    <p>ExpeDry<br/>Quick Drying Down featuring FUZE® technology</p>
    <p>Une nouvelle innovation disruptive sans produits chimiques pour tous les produits en duvet utilisés dans des climats extrêmes et humides.</p>    
    <p>ExpeDRY est une nouvelle alternative sans produits chimiques au duvet hydrophobe traité par WR. Il fonctionne grâce à une liaison permanente et non chimique de particules d&#39;or non toxiques au faisceau de duvet, ce qui facilite l&#39;évaporation des molécules d&#39;eau et permet au duvet de sécher plus&nbsp;rapidement.</p>
    <p>L&#39;évaporation est provoquée par la vibration de la molécule d&#39;eau qui se produit généralement avec l&#39;application de chaleur. Les particules d&#39;or attachées à la grappe de duvet génèrent une "excitation" similaire dans les molécules d&#39;eau sans ajout de chaleur, les faisant vibrer comme si elles étaient chauffées et accélérant finalement le processus d&#39;évaporation.</p>
    <p>Cela permet de créer une chambre isolante plus sèche, car le taux d&#39;évaporation accru est supérieur au taux d&#39;absorption du duvet dans toutes les applications du monde réel. Cela permet également d&#39;éliminer l&#39;humidité interne, telle que la transpiration lors d&#39;activités à haute énergie, ce qui peut conduire à une chaleur plus&nbsp;régulée.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/expedry?ref=tmd-com">plus d&#39;infos >></a></p>
  </>)
}

function ExpeDry(){
  return(<>
    <p>ExpeDry<br/>Quick Drying Down featuring FUZE® technology</p>
    <p>A new disruptive chemical-free innovation for all down products used in extreme and wet climates.</p>
    <p>ExpeDRY is a new chemical free alternative to WR treated hydrophobic down. It works by a permanent and chemical-free bonding of non-toxic gold particles to the down cluster which aids in the evaporation of water molecules causing the down to dry faster.</p>
    <p>Evaporation is caused through the vibration of the water molecule which usually happens with the application of heat. The gold particles attached to the down cluster generate a similar “excitement” in the water molecules without added heat causing them to vibrate as if they were heated and ultimately speeding up the process of&nbsp;evaporation.</p>
    <p>This helps to create a drier insulative chamber as the rate of increased evaporation is greater than the absorption rate of down in all real world applications. It also helps with moisture from within such as sweat in high energy activities which could ultimately lead to a more regulated&nbsp;Warmth.</p>
    <p><a href="//alliedfeather.com/info-room/products-innovations/expedry?ref=tmd-com">more info >></a></p>
  </>)
}


function SpecificTreatment(props){
  //console.log(props)  
  
  //switch
  switch(props.feature){	  
	  case "HyperDry Eco":
	  switch(props.activeLang){
	    case "fr":	    
	    return <HyperDryFR />	    
	    default:
	    return <HyperDry/>
	  }
    
      case "HyperDRY Eco":
      switch(props.activeLang){
      case "fr":	    
      return <HyperDryFR />	    
      default:
      return <HyperDry/>
      }
	  
      case "HyperDRY":
      switch(props.activeLang){
        case "fr":	    
        return <HyperDryFR />	    
        default:
        return <HyperDry/>
      }
      
	  case "HyperDry":
	  switch(props.activeLang){
	    case "fr":	    
	    return <HyperDryFR />	    
	    default:
	    return <HyperDry/>
	  }
	  
	  case "ExpeDry":
      switch(props.activeLang){
      case "fr":	    
      return <ExpeDryFR />	 
      default:
      return <ExpeDry />	 
      }
      
      case "ExpeDRY":
	  switch(props.activeLang){
	    case "fr":	    
	    return <ExpeDryFR />	 
	    default:
	    return <ExpeDry />	 
	  }
	  
	  
	  default:
	  return <>SpecificTreatment Undefined</>
  }
  
}


const Treatment = (props) => {   	
  let cleanTitle;
  switch(props.activeLang){
    case "fr":	    	    
    cleanTitle = "TRAITEMENT";
	break;
	default:
	cleanTitle = "TREATMENT"; 	
	break;
  } 	  
  if(props.apply[0]){		
    return (<><div className="content-ac tr hide" data-feature={props.apply[0]}>
    <input
      className="ac-input top"
      id="ac-8afd"
      name="ac-8afd"
      data-id="2400"      
      data-slug="TREATMENT"
      type="checkbox"
      onChange={ () => props.changeWord('treatment clicked') }
    />
    <label className="ac-label" htmlFor="ac-8afd">{ props.apply[0] } { cleanTitle }</label>
    <article className="ac-text ac-8afd">
      <div className="single-post-interior" tabIndex="0">
	    <SpecificTreatment		
	      activeLang = {props.activeLang}    
	      feature = { props.apply[0] } 
	    />
	    <footer></footer>
	  </div>
    </article>
	</div></>);
  }else{
    return(<></>)
  }
}

export default Treatment;
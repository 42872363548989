import React from 'react';
import { ddException } from '../LotException.js';

let contentArray = [
  ['FP', ['AFD_Down101-Fill-Power.jpg']],	
  ['SP',['species/species-wgd-two.jpeg','species/species-white.jpeg', 'species/species-eu-farm.png']],
  ['OR',['origin/aside-one-origin.jpg', '', 'origin/aside-two-origin.jpg']],
  ['CL',['cleanliness/cleanliness-one.jpg','', '']],
  ['CO',['content/content-one.jpg','','']],	
  ['TR',['hyperdry/aside-one-tr.jpg','hyperdry/aside-two-tr.jpg', 'hyperdry/aside-three-tr.jpg']],
  ['PR',['processing/processing-one.jpg','processing/processing-usa.jpg' ]],
  ['CR',['cert/cert-two.jpg','cert/durawash.jpg','cert/cert-one.svg']],
  ['CM',['cm/cm-one.jpg','cm/cm-two.jpg','cm/cm-three.jpg']],
  ['CA',['aside-one-ca.jpg']]
];

function regionList(loc, species){
  //console.log(loc, species);  
  switch(loc){	  
	  case "Hungary":
	  if(species.includes('White Goose')){
	    return "Eastern Southern South-Eastern";	  
	  }else if(species.includes('White Duck')){
	    return "Eastern Southern";	  
	  }else if(species.includes('Grey Goose')){
	    return "Southern";	  
	  }else{
	    return "Eastern Southern South-Eastern";
	  }
	  break;
	  
	  case "Europe":
	  if(species.includes('White Goose')){
	    return "HUNGARY";	  
	  }else if(species.includes('White Duck')){
	    return "HUNGARY";	  
	  }else if(species.includes('Grey Goose')){
	    return "HUNGARY";	  
	  }else{
	    return "HUNGARY ROMANIA";
	  }
	  
	  break;
	  default:
	  return "ORIGIN REGIONS";
	  break;	
	}  
}

function treatmentByline(treatment){
  console.log(treatment);
  
  switch(treatment){
	case "HYPERDRY":
    return "Water Resistant Down Treatment";  
    case "HyperDry":
    return "Water Resistant Down Treatment";
    case "ExpeDRY":
    return "Quick Drying Down feat FUZE technology";
    default:
    return "TREATMENT BYLINE HERE";
    break;
  }
}

function injectBuyButton(){
	console.log('inject!');	
	let lowerLeft = document.querySelector('.lower-left .lower-bg');
	/*<a href='//shop.alliedfeather.com/cart/31552984744034:1?channel=buy_button&amp;utm_source=tmd3_lotPage&amp;utm_medium=organic&amp;utm_campaign=siteLaunch' target='_blank' className='shopify-buy__btn'>Buy Now</a>*/
	let link = document.createElement("a")
	//link.innerText = "BUY NOW";
	//link.href="//shop.alliedfeather.com/cart/31552984744034:1?channel=buy_button&amp;utm_source=tmd3_lotPage&amp;utm_medium=organic&amp;utm_campaign=v3";
	link.innerText = "Get Notified";
	link.href="mailto:help@trackmydown.com?subject=AFD DownWash Restock Notification";
	link.target="_blank";
	link.id="mr_dw-purchase-cta";
	link.classList.add('shopify-buy__btn');
	
	lowerLeft.appendChild(link);
}

function clearMainRight(){
	let upperContainer = document.querySelector('.upper');
	
	if(upperContainer.childNodes.length > 1){
	  upperContainer.lastElementChild.remove();	
	}
		
	let lowerLeft = document.querySelector('.lower-left .lower-bg');
	lowerLeft.innerHTML = "";
	
	console.log('CLEARED');
}

function contentSwap(data) {		
  let bgIndex;     
  	  
  for(let i = 0; i < contentArray.length; i++){
    if(contentArray[i].indexOf(data) != -1){	  
	  bgIndex = i;	 
	}    
  }	      
  //upperActive   
}

function speciesId(x,y,z){ //props.down_type, props.origin, props.lower
	let dt, o, l;
				
	switch(x){
      case 'White Goose': dt = "wgd"; break;	 
	  case 'Grey Goose':  dt = "ggd"; break;
	  case 'Grey Duck':   dt = "gdd"; break;
	  default: 			  dt = "wdd"; break;   
	}
	
	switch(y){
	  case 'Hungary': 	 o = "hu";   break;	
	  case 'Renu Blend': o = "renu"; break;	
	  case 'Europe': 	 o = "eu";   break;
	  default:			 o = "cn";   break;	
	}
	
	switch(z){
      case 'left':  l = "left";  break;
	  case 'right': l = "right"; break;	  
	  default: 		l = "upper"; break;   
	}
	
	if(x == "Renu Blend"){
	  return(<div className="il-add" style={{ backgroundImage: `url("/img/species/species-renu-` + l + `.jpeg")`}}></div>)
	}else{
  	  return(<div className="il-add" style={{ backgroundImage: `url("/img/species/species-`+ dt +`-`+ o +`-`+ l +`.jpeg")`}}></div>)
	}
}

function originInterior(data){
  //console.log(data);
  if(data == 'cn'){
  return <div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-cn-right.jpg")`}}></div>;	
  }else{
  return <div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-eu-right.jpeg")`}}></div>;	
  }	
}

function fpChecker(data){
	let fpValue = data;
	if(fpValue > 750){
	  return fpValue = 750;	
	}else if(fpValue > 700){
	  return fpValue = 700;	
	}else{
	  return fpValue = 600;
	}
}

const ProcessingUpper = (props) => {
  //console.log(props)
  if(props.origin == "China"){
    return(<video className="il-add" src="/img/processing/processing-cn-upper.mp4" preload="true" autoPlay={true} loop={true} muted={true}></video>);	    	     
  }else{
   return(<video className="il-add" src="/img/processing/AF_D-ir_Processing_540.mp4" preload="true" autoPlay={true} loop={true} muted={true}></video>);
  }  
}

const CertVideo = (props) =>{
  if(props.recycled){
  return <><div className="vidWrap"><video className="il-add" src="./img/cert/cert-upper-grs.mp4" muted={true} preload="true" autoPlay={true} loop={true}></video></div></>	  
  }else{
  return <><div className="vidWrap"><video className="il-add" src="./img/cert/cert-upper.mp4" muted={true} preload="true" autoPlay={true} loop={true}></video></div></>
  }
}

const IlAdd = (props) => {	  
  //preload images?	
  	 
  switch(props.active){
    case "":
	return(<div className="il-add grey" style={{ backgroundImage: `url("/img/species/species-eu-farm.png")`}}></div>);
    break;
    
    case "fp":    
	if(props.lower == "left"){
	  return (<div className="il-add grey" style={{ backgroundImage: `url("/img/fillpower/fillpower-lower-one.jpg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/fillpower/fillpower-lower-two.jpg")`}}></div>);		
	}    
	break;
    
    case "sp":
    return speciesId(props.down_type, props.origin, props.lower);
	break;
    
    case "or":    
	if(props.lower == "left"){
	  if(props.origin == "Hungary"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-hu-left.jpeg")`}}></div>);			  
	  }else if(props.origin == "China"){	
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-cn-left.jpeg")`}}></div>);		
	  }else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-eu-left.jpeg")`}}></div>);			  
	  }
	}else if(props.lower == "right"){	 	  
	  if(props.origin == "Hungary"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-hu-right.jpeg")`}}></div>);			  
	  }else if(props.origin == "China"){
		return ( originInterior('cn') );	  
	  }else{
		//europe  
	    return ( originInterior('eu') );	  
	  }	  
	}else{
	  if(props.origin == "Hungary"){
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-hu-upper.jpeg")`}}></div>);			  
	  }if(props.origin == "China"){
		//top
	    if(props.down_type == "White Duck"){
		return(<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-cn-wdd.min.jpg")`}}></div>);}else if(props.down_type == "Grey Duck"){
		return(<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-cn-gdd.min.jpg")`}}></div>);}else if(props.down_type == "Grey Goose"){
		return(<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-cn-ggd.min.jpg")`}}></div>);}else{
		return (<video className="il-add" src="./img/origin/origin-720.mp4" muted={true} preload="true" autoPlay={true} loop={true}></video>);
		}
	  }else if(props.origin == "Renu Blend"){
		//renu  
		return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/renu-blend.jpg")`}}></div>);	  
	  }else{
		//top Europe  
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/origin/origin-eu-upper.jpeg")`}}></div>);	  
	  }  
	}
    break;

	case "cl":    
	if(props.lower == "left"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/cleanliness/cleanliness-left.jpeg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/cleanliness/cleanliness-right.jpeg")`}}></div>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/cleanliness/cleanliness-upper.jpeg")`}}></div>);
	}
    break;  
      
    case "tr":    
    if(props.tr == "HyperDry" || props.tr == "HyperDry Eco"){
	    if(props.lower == "left"){
		  return (<div className="il-add" style={{ backgroundImage: `url("/img/hyperdry/aside-two-tr.jpg")`}}></div>);		
	    }else if(props.lower == "right"){
		  return (<div className="il-add" style={{ backgroundImage: `url("/img/hyperdry/aside-three-tr.jpg")`}}></div>);	    
	    }else{
		 return (<div className="il-add tr" style={{ backgroundImage: `url("/img/hyperdry/aside-one-tr.jpg")`}}></div>);
	    }
	}else if(props.tr == "ExpeDRY"){
	  if(props.lower == "left"){
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/expedry/treatment-expedry-two.jpg")`}}></div>);		
	  }else if(props.lower == "right"){
		return (<div className="il-add" style={{ backgroundImage: `url("/img/expedry/treatment-expedry-three.jpg")`}}></div>);	    
	  }else{
		return (<div className="il-add tr" style={{ backgroundImage: `url("/img/expedry/treatment-expedry-one.jpg")`}}></div>);
	  }	
	}else{
	  if(props.lower == "left"){
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/hyperdry/aside-two-tr.jpg")`}}></div>);		
	  }else if(props.lower == "right"){
		return (<div className="il-add" style={{ backgroundImage: `url("/img/hyperdry/aside-three-tr.jpg")`}}></div>);	    
	  }else{
		return (<div className="il-add tr" style={{ backgroundImage: `url("/img/hyperdry/aside-one-tr.jpg")`}}></div>);
	  }			
	}	
    break;

    case "co":    
	if(props.lower == "left"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/content/contents-left.jpeg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/content/contents-right.jpeg")`}}></div>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/content/contents-upper.jpeg")`}}></div>);		
	}
    break;
    
    case "pr":
    if(props.lower == "left"){
	  if(props.loc == "China"){
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/processing/processing-cn-left.jpeg")`}}></div>);		  	    
	  }else{
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/processing/processing-usa-left.jpeg")`}}></div>);		  	    
	  }
	}else if(props.lower == "right"){
	  if(props.loc == "China"){
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/processing/processing-cn-right.jpeg")`}}></div>);		  
	  }else if(props.loc == "Montreal"){
		return (<div className="il-add" style={{ backgroundImage: `url("/img/processing/processing-mtl.jpg")`}}></div>);	
	  }else{
	    return (<div className="il-add" style={{ backgroundImage: `url("/img/processing/processing-usa.jpg")`}}></div>);			  
	  }	  
	}else{
	  return (<></>);		
	}
	break;
    
    case "cr":    
	if(props.lower == "left"){
	  return (<div className="il-add" lower="left" style={{ backgroundImage: `url("/img/cert/cert-left.jpeg")`}}></div>);		
	}else if(props.lower == "right"){	  
	  return (<div className="il-add" lower="left" style={{ backgroundImage: `url("/img/cert/cert-right.jpeg")`}}></div>);		
	}else{	 
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/content/content-one.jpg")`}}></div>);		
	}
    break;
    
    case "cm":    
	if(props.lower == "left"){
	  return (<div className="il-add " lower="left" style={{ backgroundImage: `url("/img/cm/cm-two.jpg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add" lower="right" style={{ backgroundImage: `url("/img/cm/cm-one.jpg")`}}></div>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/cm/cm-three.jpg")`}}></div>);
	}
    break;
    
    case "su":    
	if(props.lower == "left"){
	  return (<div className="il-add " lower="left" style={{ backgroundImage: `url("/img/sustainability/su-two.jpeg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add " lower="left" style={{ backgroundImage: `url("/img/sustainability/su-three.jpeg")`}}></div>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/sustainability/su-upper.jpeg")`}}></div>);
	}
    break;
    
    case "uw":    
	if(props.lower == "left"){
	  return (<div className="il-add " lower="left" style={{ backgroundImage: `url("/img/ultrawht/aside-two-uw.jpg")`}}></div>);		
	}else if(props.lower == "right"){
	  return (<div className="il-add " lower="left" style={{ backgroundImage: `url("/img/ultrawht/aside-three-uw.jpg")`}}></div>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/ultrawht/aside-one-uw.jpg")`}}></div>);
	}
    break;

    
    case "ca":    
	if(props.lower == "left"){
	  return (<></>);		
	}else if(props.lower == "right"){
	  return (<></>);		
	}else{
	  return (<div className="il-add" style={{ backgroundImage: `url("/img/care/care-upper.jpeg")`}}></div>);
	}
    break;
       	
    default:
    return(<></>);
    break;
  }  
}

const MainRight = (props) => {		
	let activeValue;
	let activeTitle;
	let cleanFPTitle, cleanSPTitle;
	
	switch(props.activeLang){
	case "fr":
	  cleanFPTitle = "POUVOIR DE REMPLISSAGE";
	break;
	default:
	  cleanFPTitle = "FILL POWER";
	break;
	}
			
	switch(props.activeSection){			
		case "FP":
		activeTitle = "FILL POWER";
		activeValue = props.verified_fp;
		
		contentSwap('FP')
		break;
		
		case "SPECIES":
			
		if(props.down_type.includes('Goose')){
		  activeTitle = "Anser Anser f. Domestica";
		}else{
		  activeTitle = "Anas Bochas Domestica";
		}		
				
		switch(props.activeLang){
		  case "fr":		  
		  switch(props.down_type){
		    case "White Goose":
		    activeValue = "L'OIE BLANCHE";
		    break;
		    case "White Duck":
		    activeValue = "CANARD BLANC";
		    break;
		    case "Grey Goose":
		    activeValue = "L'OIE CENDRÉ";
		    break;
		    case "Grey Duck":
		    activeValue = "CANARD CENDRÉ";
		    break;
		    default:
		    break;
		  }
		break;
		default:
		  activeValue = props.down_type;
		break;
		}
		
		break;
		
		case "ORIGIN":
		//activeTitle = regionList(props.origin[0], props.down_type);
		if(props.down_type == "Renu Blend"){
		  //RENU
		  activeTitle = "";	
		}else{
		  activeTitle = regionList(props.origin[0], props.down_type);	
		}
		activeValue = props.origin[0];
		break;
		
		case "CLEANLINESS":
		activeTitle = "TURBIDITY";
		activeValue = props.turbidity+"MM";
		break;
		
		case "CONTENT":
		activeValue = props.down_cluster+"%";		
		switch(props.activeLang){
		  case "fr":		  		  
		  activeTitle = "PLUMULE";
		  break;
		  default:
		  activeTitle = "CLUSTER CONTENT";
		  break;
		}

		break;
		
		case "TREATMENT":
		if(props.treatment[0] == "HyperDry Eco"){
		  activeValue = "HYPERDRY";	
		}else{
		  activeValue = props.treatment[0];	
		}		
		activeTitle = treatmentByline(activeValue);
		break;
		
		case "PROCESSING":
		if(props.facility != ""){ 
		  activeValue =  props.facility
		}else{ 
		  activeValue =  "Los Angeles" 
		};
				
		switch(props.activeLang){
		  case "fr":		  		  
		  activeTitle = "INSTALLATION DE TRAITEMENT";
		  break;
		  default:
		  activeTitle = "PROCESSING FACILITY";
		  break;
		}
		break;
		
		case "SUSTAINABILITY":
		switch(props.activeLang){
		  case "fr":		  
		  activeValue = "85-97%"
		  activeTitle = "RÉDUIRE L'IMPACT ENVIRONNEMENTAL"
		  break;
		  default:
		  activeValue = "85-97%"
		  activeTitle = "LOWER ENVIRONMENTAL IMPACT"
		  break;
		}		
		//injectBuyButton();
		break;
		
		case "CARE":
		switch(props.activeLang){
		  case "fr":		  
		  activeValue = "DUVET"
		  activeTitle = "COMMENT NETTOYER VOTRE"
		  break;
		  default:
		  activeValue = "DOWN"
		  activeTitle = "HOW TO CLEAN YOUR"
		  break;
		}		
		//injectBuyButton();
		break;
				
		case "LOT":
		contentSwap('LOT');
		break;
				
		default:
		activeTitle = "FILL POWER";
		activeValue = props.verified_fp;
		
		break
	}
	
	let ddZero, ddFP, ddLF, ddQ;
	
	if(props.prefix == '0404'){
	  ddZero = true;	  	  	  
	  ddException(props.verified_fp,props.land_fowl, props.quill).then(
	  (result) => {		
		console.log(result);
		ddFP = result.vfp;
		ddLF = result.land_fowl;
		ddQ  = result.quill;	
	  }, (result) => {
	    //FAIL
		console.log('Error');
	  });
	}
			 
    return (<>
	<aside className="main-right" 
		data-brand	   = { props.prefix }
		data-ddfp      = { ddFP }
		data-ddlf      = { ddLF }
		data-ddq       = { ddQ }
	    data-loc       = { props.activeLang } 
		data-active    = { props.activeSection } 
		data-rds       = { props.rds != undefined ? props.rds : false }
		data-grs       = { props.down_type == "Renu Blend" ? true : false }
		data-fp        = { props.down_type == "Renu Blend" ? fpChecker(props.verified_fp) : false }
		data-treatment ={ props.treatment[0] ? props.treatment[0] : false }
	>
		<div className="upper">
			<div className="il upper-bg"></div>
			{ props.activeSection == "FP" ? <video className="il-add" src="./img/fillpower/AF_D-ir_FillPower_720.mp4" muted={true} preload="true" autoPlay={true} loop={true}></video> : null }
			{ props.activeSection == "SPECIES" ? <IlAdd active="sp" down_type={props.down_type} origin={props.origin[0]} /> : null }
			{ props.activeSection == "ULTRAWHT" ? <IlAdd active="uw" /> : null }
			{/* props.activeSection == "ORIGIN" ? <IlAdd active="or" down_type={props.down_type} origin={props.origin[0]} /> : null */}
			{/* props.activeSection == "ORIGIN" ? <div className="frameWrap"><iframe className="vimeo" src="https://player.vimeo.com/video/728933267?autoplay=1&loop=1&autopause=0&muted=1&background=1&dnt=1&h=98ec485386&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay;" autoplayfullscreen="true" muted={true}title="Sourcing_SupplyChain"></iframe></div> : null */}						
			{ props.activeSection == "ORIGIN" ? <IlAdd active="or" origin={props.origin[0]} down_type={props.down_type} /> : null }
			{ props.activeSection == "CLEANLINESS" ? <IlAdd active="cl" /> : null }	
			{ props.activeSection == "TREATMENT" ? <IlAdd active="tr" tr={props.treatment[0]} /> : null }	
			{ props.activeSection == "CONTENT" ? <IlAdd active="co" /> : null }	
			{ props.activeSection == "PROCESSING" ? <ProcessingUpper origin={props.origin[0]} /> : null }
			{props.activeSection == "CERTIFICATIONS" ? <CertVideo recycled={props.recycled} />: null }
			
			{/* props.activeSection == "CERTIFICATIONS" ? <IlAdd active="cr" /> : null */}		
			{ props.activeSection == "CM" ? <IlAdd active="cm" /> : null }	
			{ props.activeSection == "SUSTAINABILITY" ? <IlAdd active="su" /> : null }
			{ props.activeSection == "CARE" ? <IlAdd active="ca" /> : null }	
		</div>
		<div className="lower">
			<div className="lower-left">
				<div className="active-lot"><h3>				 
				  {props.display_lotAlt != undefined
				  ? props.display_lotAlt
				  : props.display_lot}
				</h3></div>
				<div className="il lower-bg"></div>		
				{ props.activeSection == "FP" ? <IlAdd active="fp" lower="left" /> : null }						
				{ props.activeSection == "ORIGIN" ? <IlAdd active="or" lower="left" origin={props.origin[0]} /> : null }						
				{ props.activeSection == "CLEANLINESS" ? <IlAdd active="cl" lower="left"/> : null }	
				{ props.activeSection == "SPECIES" ? <IlAdd active="sp" lower="left" down_type={props.down_type} origin={props.origin[0]} /> : null }
				{ props.activeSection == "ULTRAWHT" ? <IlAdd active="uw" lower="left"/> : null }
				{ props.activeSection == "TREATMENT" ? <IlAdd active="tr" lower="left" tr={props.treatment[0]} /> : null }	
				{ props.activeSection == "CONTENT" ? <IlAdd active="co" lower="left"/> : null }					
				{ props.activeSection == "PROCESSING" ? <IlAdd active="pr" lower="left" loc={props.facility} /> : null }	
				{ props.activeSection == "CERTIFICATIONS" ? <IlAdd active="cr" lower="left"/> : null }	
				{ props.activeSection == "CM" ? <IlAdd active="cm" lower="left"/> : null }	
				{ props.activeSection == "SUSTAINABILITY" ? <><a id="mr-pow-cta" href="//protectourwinters.org/join-team-pow/?channel=tmd_afd" target="_blank" className="shopify-buy__btn ei">JOIN POW</a> <IlAdd active="su" lower="left"/></> : null }
				{ props.activeSection == "CARE" ? 				  
				  <a id="mr_dw-purchase-cta" href="mailto:help@trackmydown.com?subject=AFD DownWash Restock Notification" target="_blank" className="shopify-buy__btn">Get Notified</a>
				  : null }			
			</div>
			<div className="lower-right">				
				<small className="lower-credit"></small>
				{ props.activeSection == "LOT" ? 
				  <div className="sticky-desc">
				  <p className="spec-title">{ cleanFPTitle }</p>
				  <p className="spec-value">{ props.verified_fp }</p>				  				  
				  </div>	
				: null }					
				  <div className="active-desc">
				  	{ props.activeSection == "CARE" ? 
					<a target="_blank" className="care-link" href="//alliedfeather.com/info-room/down-care/how-to-clean-your-down"></a> 
					: null }
				    <p className="spec-title">{ activeTitle } { props.activeSection == "CARE" ? <span>DOWN</span> : null }</p>
				    <p className="spec-value">{ activeValue }</p>
				  </div>
				  <div className="il lower-bg"></div>
				  { props.activeSection == "FP" ? <IlAdd active="fp" lower="right" /> : null }						
				  { props.activeSection == "SPECIES" ? <IlAdd active="sp" lower="right" loc={props.activeLang} down_type={props.down_type} origin={props.origin[0]} /> : null }	
				  { props.activeSection == "ULTRAWHT" ? <IlAdd active="uw" lower="right"/> : null }
				  { props.activeSection == "ORIGIN" ? <IlAdd active="or" origin={props.origin[0]} lower="right" /> : null }	
				  { props.activeSection == "CLEANLINESS" ? <IlAdd active="cl" lower="right"/> : null }	
				  { props.activeSection == "TREATMENT" ? <IlAdd active="tr" lower="right" tr={props.treatment[0]} /> : null }	
				  { props.activeSection == "CONTENT" ? <IlAdd active="co" lower="right"/> : null }					
				  { props.activeSection == "PROCESSING" ? <IlAdd active="pr" lower="right" loc={props.facility} /> : null }	
				  { props.activeSection == "CERTIFICATIONS" ? <IlAdd active="cr" lower="right"/> : null }	
				  { props.activeSection == "SUSTAINABILITY" ? <IlAdd active="su" lower="right"/> : null }
				  { props.activeSection == "CM" ? <IlAdd active="cm" lower="right"/> : null }	
				  
			</div>				
		</div>
	</aside>	    	    
    </>);    
}

export default MainRight;